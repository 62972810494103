import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';

export default class NavMenu extends React.PureComponent<{}, { isOpen: boolean }> {
    public state = {
        isOpen: false
    };

    public render() {
        /*
        //let update: UpdateState = { ShowMopar: false }
        //update.ShowMopar = localStorage.getItem('mopar') as any;
        //if(update.ShowMopar){
            return (
                <header>
                    <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" dark>
                        <Container>
                            <NavbarBrand >
                                <div className="logo">
                                    <img src="../UpdateAgent1.0_Header.png" alt="UpdateAgent" ></img>
                                </div>
                            </NavbarBrand>                        
                        </Container>
                    </Navbar>
                </header>
            );
        //}
        */
        /* saved this menu in case we need it later
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" dark>
                    <Container>
                        <NavbarBrand tag={Link} to="/">
                            <div className="logo">
                                <img src="../UpdateAgent1.0_Header.png" alt="UpdateAgent" ></img>
                            </div>
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggle} className="mr-2"/>
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={this.state.isOpen} navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <NavLink tag={Link} className="text-light" to="/">Home</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-light" to="/update">Update</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-light" to="/login">Login / Logout</NavLink>
                                </NavItem>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
        */
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" dark>
                    <Container>
                        <NavbarBrand tag={Link} to="/update">
                            <div className="logo">
                                <img src="../UpdateAgent1.0_Header.png" alt="UpdateAgent" ></img>
                            </div>
                        </NavbarBrand>
                    </Container>
                </Navbar>
            </header>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}
