import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Redirect } from 'react-router';
import { ApplicationState } from '../store';
import * as AuthenticationStore from '../store/Authentication';
import { javascriptSetupComplete } from '../store/Update';
import { loadUser } from '../devices/DeviceFactory';
import './NavMenu.css';

type AuthenticationProps =
    AuthenticationStore.AuthenticationState &
    typeof AuthenticationStore.actionCreators &
    RouteComponentProps<{}>;

class Login extends React.PureComponent<AuthenticationProps> {
    constructor(props: Readonly<AuthenticationProps>) {
        super(props);

        if (window.onLoadUser == null) {
            window.onLoadUser = (user: string) => {
                props.doLoadUser(user);
            };

            loadUser();
        }

        console.log("login setting javascriptSetupComplete");
        window.javascriptSetupComplete = javascriptSetupComplete;

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e: { target: { name: any; value: any; }; }) {
        const { name, value } = e.target;
        this.props.updateValue(name, value);
    }

    public render() {
        let tempUser = this.props.user;
        if (tempUser != null && tempUser.guid != null && tempUser.guid.length > 0 && !this.props.loggingIn && !this.props.redirectToUpdateDoneFlag) {
            this.props.doLoadUser(JSON.stringify(tempUser));
            return (
                <React.Fragment>
                    <div>
                        <Redirect to='update' />
                    </div>
                </React.Fragment>
                );
        }

        if (this.props.isLoggedIn) {
            return (
                <React.Fragment>
                    <div>
                        <h1>Logout</h1>

                        <button type="button"
                            className="btn btn-primary btn-lg"
                            onClick={() => { this.props.logout(this.props.user.guid); }}>
                            Logout
                        </button>
                    </div>
                </React.Fragment>
            );
        }
        else if (this.props.needRegister) {
            return (
                <React.Fragment>
                    <div>
                        <h1>Register</h1>

                        <form name="form">
                            <div className="form-group">
                                <p><strong>Account</strong><br />
                                    Please enter your registration information to create or access an existing account</p>
                            </div>
                            <div className={'form-group' + (this.props.authError && this.props.authError.length > 0) ? 'has-error' : 'is-hidden'}>
                                <label className='has-error'>{this.props.authError}</label>
                            </div>
                            <div className={'form-group' + (this.props.submitted && !this.props.tempUser.firstName ? ' has-error' : '')}>
                                <label htmlFor="firstName">First Name</label>
                                <input type="text" className="form-control" name="firstName" value={this.props.tempUser.firstName} onChange={this.handleChange} />
                                {this.props.submitted && !this.props.tempUser.firstName &&
                                    <div className="help-block">First Name is required</div>
                                }
                            </div>
                            <div className={'form-group' + (this.props.submitted && !this.props.tempUser.lastName ? ' has-error' : '')}>
                                <label htmlFor="lastName">Last Name</label>
                                <input type="text" className="form-control" name="lastName" value={this.props.tempUser.lastName} onChange={this.handleChange} />
                                {this.props.submitted && !this.props.tempUser.lastName &&
                                    <div className="help-block">First Name is required</div>
                                }
                            </div>
                            <div className={'form-group' + (this.props.submitted && !this.props.tempUser.email ? ' has-error' : '')}>
                                <label htmlFor="email">Email</label>
                                <input type="text" className="form-control" name="email" value={this.props.tempUser.email} onChange={this.handleChange} />
                                {this.props.submitted && !this.props.tempUser.email &&
                                    <div className="help-block">Email is required</div>
                                }
                            </div>
                            <div className="form-group">
                                <button type="button"
                                    className="btn btn-primary btn-lg"
                                    onClick={() => { this.props.register(this.props.tempUser.email, '', this.props.tempUser.firstName, this.props.tempUser.lastName); }}>
                                    Register</button>
                                {this.props.loggingIn &&
                                    <img alt="Logging In" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                }
                            </div>
                        </form>
                    </div>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <div>
                    <h1>Login</h1>

                    <form name="form">
                        <div className={'form-group' + (this.props.authError && this.props.authError.length > 0) ? 'has-error' : 'is-hidden'}>
                            <label className='has-error'>{this.props.authError}</label>
                        </div>
                        <div className={'form-group' + (this.props.submitted && !this.props.tempUser.email ? ' has-error' : '')}>
                            <label htmlFor="email">Email</label>
                            <input type="text" className="form-control" name="email" value={this.props.tempUser.email} onChange={this.handleChange} />
                            {this.props.submitted && !this.props.tempUser.email &&
                                <div className="help-block">Email is required</div>
                            }
                        </div>
                        <div className={'form-group' + (this.props.submitted && !this.props.tempUser.password ? ' has-error' : '')}>
                            <label htmlFor="password">Password</label>
                            <input type="password" className="form-control" name="password" value={this.props.tempUser.password} onChange={this.handleChange} />
                            {this.props.submitted && !this.props.tempUser.password &&
                                <div className="help-block">Password is required</div>
                            }
                        </div>
                        <div className="form-group">
                            <button type="button"
                                className="btn btn-primary btn-lg"
                                onClick={() => { this.props.login(this.props.tempUser.email, this.props.tempUser.password); }}>
                                Login</button>
                            {this.props.loggingIn &&
                                <img alt="Logging In" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                            }
                        </div>
                        <div className="form-group">
                            <p>Don't have an account? Click <button type="link" className="link" onClick={() => { this.props.needToRegister(); }}>here</button> to register.</p>
                        </div>
                    </form>
                </div>
            </React.Fragment>
        );
    }
};

export default connect(
    (state: ApplicationState) => state.authentication,
    AuthenticationStore.actionCreators
)(Login as any);
