import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Redirect } from 'react-router';
import { ApplicationState } from '../store';
import * as UpdateStore from '../store/Update';
import './NavMenu.css';
import { javascriptSetupComplete } from '../store/Update';
import { finishUpdate } from '../devices/DeviceFactory';

declare global {
	interface Window {
        props: UpdateProps;
        javascriptSetupComplete: Function;
        javascriptSetupFlag: boolean;
        saveUser: Function;
        loadUser: Function;
        onLoadUser: Function;
        loginComplete: Function;
        getDeviceState: Function;
        onAuthCodeError: Function;
        onAuthCodeFinished: Function;
		OnDeviceState: Function;
		checkGetDeviceState: Function;
		getUploadFileData: Function;
		onUploadFileProgress: Function;
		onUploadFileFinished: Function;
		noFileFound: Function;
		onUploadFileError: Function;
		sendUploadFile: Function;
		onDownloadFileProgress: Function;
		onDownloadFileProgressMessage: Function;
		onDownloadFileFinished: Function;
		onDownloadFileError: Function;
		processDownloadFile: Function;
		checkForUpdates: Function;
		finishUpdate: Function;
		cleanup: Function;
		requestPowerOff: Function;
		onPowerOffComplete: Function;
		requestPowerOn: Function;
		onPowerOnComplete: Function;
		clearDTCs: Function;
        onClearDTCsComplete: Function;
        userFeedback: Function;
        restartUpdate: Function;
        mobileAppLogger: Function;
	}
}

type UpdateProps =
	UpdateStore.UpdateState &
	typeof UpdateStore.actionCreators &
	RouteComponentProps<{}>;

class Update extends React.PureComponent<UpdateProps> {
	constructor(props: UpdateProps) {
		super(props);

        console.log("update setting javascriptSetupComplete");
        window.javascriptSetupComplete = javascriptSetupComplete;

        window.userFeedback = (message: string) => {
            this.props.doUserFeedback(message);
        }

        window.checkForUpdates = () => {
			props.checkForUpdates();
		}

		let self = this;

        let processingLoop = function () {
			if (self.props.autoProcessNextItem) {
				self.props.setAutoProcessNextItem(false);
				self.props.processItem();
			}
			setTimeout(processingLoop, 100);
		}

		setTimeout(processingLoop, 0);
	}

    public render() {
        if (this.props.UserGuid === null || (this.props.UserGuid as string).length == 0) {
            return (
                <React.Fragment>
                    <div>
                        <Redirect to='login' />
                    </div>
                </React.Fragment>
            );
        }

        let infoDisplay = null;
        if (this.props.ShowMopar) {
            return (
                <React.Fragment>
                    <div className='grid-container'>
                        <div className='grid-row'>
                            <h1>Enter Mopar Authorization Code</h1>
                        </div>
                        <div className='grid-row'>
                            <input name='one' className='moparinput left' type='text' value={this.props.AuthCode1} onChange={(evt) => { this.props.onChangeText(evt); }}></input>-
                            <input name='two' className='moparinput right' type='text' value={this.props.AuthCode2} onChange={(evt) => { this.props.onChangeText(evt); }}></input>
                            <p>

                            </p>
                            <button type="button"
                                className="btn btn-primary btn-lg"
                                onClick={() => { this.props.onClickPrompt('Save'); }}
                                disabled={this.props.SaveDisabled}>
                                Save
                            </button>
                            <p className='showMess' >{this.props.showMessage}</p>
                            <p className='has-error'>{this.props.AuthError}</p>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        else if (this.props.updateError.length > 0) {
            finishUpdate();
            return (
                <React.Fragment>
                    <div>
                        <h1>Updates (step {this.props.currentItemIndex}) </h1>

                        <h2>Update Error:</h2>

                        <div className={'has-error'}>
                            <p className='has-error'>{this.props.updateError}</p>
                        </div>

                    </div>
                </React.Fragment>
            );
        }
        else if (this.props.AuthError.length > 0) {
            finishUpdate();
            return (
                <React.Fragment>
                    <div>
                        <h1>AuthCode (step {this.props.currentItemIndex}) </h1>

                        <h2>AuthCode Error:</h2>

                        <div className={'has-error'}>
                            <label className='has-error'>{this.props.AuthError}</label>
                        </div>

                    </div>
                </React.Fragment>
            );
        }
        else if (this.props.showMessage.length > 0) {
            return (
                <React.Fragment>
                    <div>
                        <h1>Updates (step {this.props.currentItemIndex})</h1>

                        <h2>Update</h2>

                        <p>{this.props.showMessage}</p>

                    </div>
                </React.Fragment>
            );
        }
        else if (this.props.finalDisplay) {
            finishUpdate();
            infoDisplay = this.processRetrieveInfo();
            if (this.props.retrieveOnly) {
                return (
                    <React.Fragment>
                        <div>
                            <h1>Device is up to date</h1>
                            <h2>Press Back to Continue</h2>

                            {infoDisplay}
                        </div>
                    </React.Fragment>
                );
            }

            return (
                <React.Fragment>
                    <div>
                        <h1>Update Finished</h1>
                        <h2>Press Back to Continue</h2>

                        <p>{this.props.finalMessage}</p>
                        {infoDisplay}

                    </div>
                </React.Fragment>
            );
        }
        else if (this.props.userCancelled) {
            finishUpdate();
            infoDisplay = this.processRetrieveInfo();
            return (
                <React.Fragment>
                    <div>
                        <h1>Updates (step {this.props.currentItemIndex}) </h1>

                        <h2>User Cancelled</h2>
                        <h2>Press Back to Continue</h2>

                        {infoDisplay}

                    </div>
                </React.Fragment>
            );
        }
        else if (this.props.needRegister || this.props.needLogout) {
            return (
                <React.Fragment>
                    <div>
                        <Redirect to='login' />
                    </div>
                </React.Fragment>
            );
        }
        else if (this.props.DeviceState != null && this.props.DeviceState.SerialNumber.length > 0) {
            if (this.props.isCheckingForUpdates) {
                infoDisplay = this.processRetrieveInfo();
                return (
                    <React.Fragment>
                        <div>
                            <h1>Updates (step {this.props.currentItemIndex}) </h1>

                            <h2>Checking for updates. Please wait...</h2>
                            {infoDisplay}
                        </div>
                    </React.Fragment>
                );
            }
            else if (this.props.isUpToDate) {
                finishUpdate();
                infoDisplay = this.processRetrieveInfo();
                return (
                    <React.Fragment>
                        <div>
                            <h1>Updates</h1>

                            <h2>Device is up to date</h2>
                            <h2>Press Back to Continue</h2>

                            {infoDisplay}

                        </div>
                    </React.Fragment>
                );
            }
            else if (this.props.installation != null && this.props.installation.InstallationId != null && this.props.installation.InstallationId.length > 0) {
                let navItems: any = null;
                if (this.props.menuItems) {
                    let menuItems = this.props.menuItems;
                    navItems = Object.keys(this.props.menuItems).map((key, index) => {
                        let target: string = "_top";
                        if (menuItems[key].UseExternalBrowser)
                            target = "_blank";

                        return (
                            <a className="text-dark" target={target} href={menuItems[key].Url}>{key}</a>
                        );
                    })
                }

                let currentItem: any = null;
                let updateBody: any = null;
                if (this.props.installation.Items != null) {
                    if (this.props.currentItemIndex >= 0 && this.props.currentItemIndex < this.props.installation.Items.length) {
                        currentItem = this.props.installation.Items[this.props.currentItemIndex];

                        if (currentItem.Metadata.Retrieve) {
                            updateBody = this.processRetrieve(currentItem);
                        }
                        else if (currentItem.Metadata.UpdateType === 'Prompt') {
                            updateBody = this.processPromptUpdateType(currentItem);
                        }
                        else if (currentItem.Metadata.UpdateType) {
                            updateBody = this.processDownload(currentItem);
                        }/**/
                    }
                }
                infoDisplay = this.processRetrieveInfo();

                return (
                    <React.Fragment>
                        <div>
                            <h1>Updates (step {this.props.currentItemIndex}) </h1>

                            {
                                navItems &&
                                <ul className="navbar-nav flex-grow">
                                    {navItems}
                                </ul>
                            }

                            <h2>Updates found for {this.props.DeviceState.SerialNumber}:</h2>
                            <div className="div-center">
                                <hr />
                                {updateBody}
                                <hr />
                                {infoDisplay}
                            </div>
                        </div>
                    </React.Fragment>
                );
            }

            this.props.checkForUpdates();

            return (
                <React.Fragment>
                    <div>
                        <h1>Updates</h1>

                        <h2>Device Information</h2>


                    </div>
                </React.Fragment>
            );
        }

        window.props = this.props;
        window.checkGetDeviceState = function () {
            console.log("checkGetDeviceState");
            window.props.checkGetDeviceState();
        };

        return (
            <React.Fragment>
                <div>
                    <h1>Updates</h1>

                    <h2>Waiting for a Device to Connect.</h2>

                    <div>
                        {
                            this.props.userFeedback.map((value) => (<div>{value}</div>))
                        }
                    </div>
                    {/*
                        <button type="button"
                            className="btn btn-primary btn-lg"
                            onClick={() => { window.checkGetDeviceState(); }}>
                            Mock Device
					    </button>
                    */}
                    {/*
                        <button type="button"
                            className="btn btn-primary btn-lg"
                            onClick={() => { this.props.doLogout(); }}>
                            Logout
					    </button>
                    */}
                </div>
            </React.Fragment>
        );
    }

	processRetrieve(currentItem: any) {
		let updateBody: any = null;

		updateBody =
			(
				<div>
					<p>Retrieving {currentItem.Metadata.Retrieve} from device...</p>
					<p>Percent Complete: {this.props.progress}</p>
				</div>
                
			);

		return updateBody;
	}
    processRetrieveInfo() {
        let infoDisplay: any = null;
        let notes = this.props.notes;

        if (this.props.finalDisplay) {
            infoDisplay =
                (
                    <div className="div-left">
                        <p>Device Serial Number: {this.props.DeviceState.SerialNumber}</p>
                        <p>Device SKU: {this.props.Sku}</p>
                        <p>Installed Update: {notes}</p>
                    </div>
                );
        }
        else {
            if (notes !== null && notes.length > 0 && !this.props.retrieveOnly) {
                infoDisplay =
                    (
                        <div className="div-left">
                            <p>Device Serial Number: {this.props.DeviceState.SerialNumber}</p>
                            <p>Device SKU: {this.props.Sku}</p>
                            <p>Current Versions: {this.props.DeviceState.MainVersions}</p>
                            <p>{this.props.DeviceState.AuxVersions}</p>
                            <p>{this.props.DeviceState.DSPICVersions}</p>
                            <p>Available Update: {notes}</p>
                        </div>
                    );
            }
            else {
                infoDisplay =
                    (
                        <div className="div-left">
                            <p>Device Serial Number: {this.props.DeviceState.SerialNumber}</p>
                            <p>Device SKU: {this.props.Sku}</p>
                            <p>Current Versions: {this.props.DeviceState.MainVersions}</p>
                            <p>{this.props.DeviceState.AuxVersions}</p>
                            <p>{this.props.DeviceState.DSPICVersions}</p>
                        </div>
                    );
            }
        }

		return infoDisplay;
	}
	processDownload(currentItem: any) {
        let updateBody: any = null;
        let downloadFileName = currentItem.Metadata.Filename;
        let index = downloadFileName.indexOf('group_update');
        if (index > -1)
            downloadFileName = downloadFileName.slice(index);
        downloadFileName = downloadFileName
            .replace('/Main', 'Main - ')
            .replace('/Aux', 'Aux - ')
            .replace('/pulsar.XMC', 'Main')
            .replace('/pulsar4.FPGA', 'Logic')
            .replace('/pulsar16.FPGA', 'Logic')
            .replace('/pulsar.CAL', 'Calibration')

		updateBody =
			(
                <div>
                    <p className="has-error">Do NOT close or switch away from this app during the entire update process.</p>
                    <p>Downloading {downloadFileName} to device...</p>
					<p>{this.props.progressMessage}</p>
					<p>Percent Complete: {this.props.progress}</p>
				</div>
			);

		return updateBody;
	}

	processPromptUpdateType(currentItem: any) {
		let updateBody: any = null;

		switch (currentItem.Metadata.PromptType) {
			case 'Ok':
				updateBody =
					(
						<div className="prompt-add-on">
							<p>{currentItem.Metadata.Message}</p>
							<button type="button"
								className="btn btn-primary btn-lg"
								onClick={() => { this.props.onClickPrompt('OK'); }}>
								OK
							</button>
						</div>
					);
				break;
            case 'YesContinueNoExit':
				updateBody =
					(
						<div>
							<p>{currentItem.Metadata.Message}</p>
							<button type="button"
								className="btn btn-primary btn-lg"
								onClick={() => { this.props.onClickPrompt('OK'); }}>
								{currentItem.Metadata.ShowNo ? 'Yes' : 'OK'}
							</button>
							{
								currentItem.Metadata.ShowNo ?
									<button type="button"
										className="btn btn-primary btn-lg"
										onClick={() => { this.props.onClickPrompt('No'); }}>
										No
									</button>
									: ''
							}
						</div>
					);
                break;

            case 'RebootNotification':
                updateBody =
                    (
                        <div>
                            <p>{currentItem.Metadata.Message}</p>
                        </div>
                    );
                this.props.requestDevicePowerOff();
                break;

            case 'Waiting':
                updateBody =
                    (
                        <div>
                            <p>{currentItem.Metadata.Message}</p>
                        </div>
                    );
                this.props.clearDTCs();
                break;
		}

		return updateBody;
	}
};

export default connect(
    (state: ApplicationState) => state.update,
    UpdateStore.actionCreators
)(Update as any);
