import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { saveUser } from '../devices/DeviceFactory';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface AuthenticationState {
    user: User;
    tempUser: User;
    isLoggedIn: boolean;
    needRegister: boolean;
    redirectToUpdateDoneFlag: boolean;
    forgotPassword: boolean;
    loggingIn: boolean;
    submitted: boolean;
    authError: string;
}

export interface User {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    guid: string;
    userID: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface RegisterAction { type: 'REGISTER', user: User }
export interface LoginAction { type: 'LOGIN', user: User }
export interface LogoutAction { type: 'LOGOUT', user: User }
export interface DoLogoutAction { type: 'DO_LOGOUT' }
export interface LoadUserAction { type: 'LOAD_USER', user: User }
export interface RedirectToUpdateDoneAction { type: 'REDIRECT_TO_UPDATE_DONE' }
export interface UpdateValueAction { type: 'UPDATE_VALUE', name: string, value: string }
export interface ErrorAction { type: 'ERROR', error: string, needRegister: boolean }
export interface IsLoggingInAction { type: 'IS_LOGGING_IN', isLoggingIn: boolean }
export interface NeedRegisterAction { type: 'NEED_REGISTER' }
export interface ForgotPasswordAction { type: 'FORGOT_PASSWORD' }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RegisterAction | LoginAction | LogoutAction | DoLogoutAction | LoadUserAction | RedirectToUpdateDoneAction | UpdateValueAction | ErrorAction | IsLoggingInAction | NeedRegisterAction | ForgotPasswordAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

function handleErrors(response: any) {
    if (!response.ok) {
        return response.json();
    }

    return response;
}

export const actionCreators = {
    doLoadUser: (userString: string): AppThunkAction<KnownAction> => (dispatch) => {
        let user = { email: '', password: '', firstName: '', lastName: '', guid: '', userID: 0 } as User;
        if (userString != null && userString.length > 0) {
            user = JSON.parse(userString) as User;
        }

        dispatch({ type: 'LOAD_USER', user });
	},
    needToRegister: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'NEED_REGISTER' });
    },
    redirectToUpdateDone: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'REDIRECT_TO_UPDATE_DONE' });
    },
    register: (email: string, password: string, firstName: string, lastName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.authentication) {
            let data = {
                email: email,
                password: password,
                firstName: firstName,
                lastName: lastName,
                userGuid: null
            };

            dispatch({ type: 'IS_LOGGING_IN', isLoggingIn: true });

            fetch(`Authentication/Register`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            })
                .then(response => {
                    dispatch({ type: 'IS_LOGGING_IN', isLoggingIn: false });
                    return response;
                })
                .then(response => handleErrors(response))
                .then(response => {
                    if (response.error !== undefined)
                        throw Error(response.error);

                    return response;
                })
                .then(response => response.json() as Promise<User>)
                .then(user => {
                    dispatch({ type: 'REGISTER', user: user });
                })
                .catch(error => {
                    dispatch({ type: 'ERROR', error: error.message, needRegister: false });
                })
        }
    },
    login: (email: string, password: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.authentication) {
            let data = {
                email: email,
                password: password
            };

            dispatch({ type: 'IS_LOGGING_IN', isLoggingIn: true });

            fetch(`Authentication/Login`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            })
                .then(response => {
                    dispatch({ type: 'IS_LOGGING_IN', isLoggingIn: false });
                    return response;
                })
                .then(response => handleErrors(response))
                .then(response => {
                    if (response.error !== undefined)
                        throw Error(response.error);

                    return response;
                })
                .then(response => response.json() as Promise<User>)
                .then(user => {
                    dispatch({ type: 'LOGIN', user: user });
                })
                .catch(error => {
                    dispatch({ type: 'ERROR', error: error.message, needRegister: false });
                })
        }
    },
    logout: (userGuid: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.authentication) {
            let data = {
                userGuid: userGuid
            };
            fetch(`Authentication/Logout`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            })
                .then(response => handleErrors(response))
                .then(response => {
                    if (response.error !== undefined)
                        throw Error(response.error);

                    return response;
                })
                .then(response => response.json() as Promise<User>)
                .then(user => {
                    saveUser('');
                    dispatch({ type: 'LOGOUT', user: user });
                })
                .catch(error => {
                    dispatch({ type: 'ERROR', error: error.message, needRegister: true });
                })
        }
    },
    forgotPasswordAction: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'FORGOT_PASSWORD' });
    },
    updateValue: (name: string, value: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.authentication) {
            dispatch({ type: 'UPDATE_VALUE', name: name, value: value });
        }
    },
    error: (error: string, needRegister: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.authentication) {
            dispatch({ type: 'ERROR', error: error, needRegister: needRegister });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: AuthenticationState = { 
    user: { email: '', password: '', firstName: '', lastName: '', guid: '', userID: 0 }, 
    tempUser: { email: '', password: '', firstName: '', lastName: '', guid: '', userID: 0 }, 
    isLoggedIn: false, 
    needRegister: false, 
    redirectToUpdateDoneFlag: false, 
    forgotPassword: false, 
    loggingIn: false, 
    submitted: false, 
    authError: '' 
};

export const reducer: Reducer<AuthenticationState> = (state: AuthenticationState | undefined, incomingAction: Action): AuthenticationState => {
    if (state === undefined) {
        let newState = {
            ...unloadedState
        };
        return newState;
    }

    let emptyUser = { email: '', password: '', firstName: '', lastName: '', guid: '', userID: 0 } as User;
    const action = incomingAction as KnownAction;

    if ((action.type as string) === '@@router/LOCATION_CHANGE') {
        let redirectToUpdateDoneFlag = false;
        let genericAction = action as any;
        if (genericAction.payload != null && genericAction.payload.location != null && genericAction.payload.location.pathname != null && genericAction.payload.location.pathname == '/update') {
            redirectToUpdateDoneFlag = true;
        }
        return {
            ...state, redirectToUpdateDoneFlag: redirectToUpdateDoneFlag
        };
    }
    else {
        switch (action.type) {
            case 'LOAD_USER':
                return {
                    ...state, user: action.user
                };
            case 'NEED_REGISTER':
                return {
                    ...state, needRegister: true, authError: ''
                };
            case 'REDIRECT_TO_UPDATE_DONE':
                return {
                    ...state, redirectToUpdateDoneFlag: true, authError: ''
                };
            case 'REGISTER':
                saveUser(JSON.stringify(action.user));
                let registerState = {
                    ...state, user: { ...action.user }, isLoggedIn: true, authError: ''
                };
                return registerState;
            case 'LOGIN':
                saveUser(JSON.stringify(action.user));
                let loginState = {
                    ...state, user: { ...action.user }, redirectToUpdateDoneFlag: false, loggingIn: false, isLoggedIn: true, authError: ''
                };
                return loginState;
            case 'IS_LOGGING_IN':
                return {
                    ...state, redirectToUpdateDoneFlag: false, loggingIn: action.isLoggingIn
                };
            case 'LOGOUT':
                saveUser('');
                return {
                    ...state, user: { ...emptyUser }, isLoggedIn: false, authError: ''
                };
            case 'DO_LOGOUT':
                saveUser('');
                return {
                    ...state, user: { ...emptyUser }, isLoggedIn: false, authError: ''
                };
            case 'FORGOT_PASSWORD':
                return {
                    ...state, forgotPassword: true
                };
            case 'UPDATE_VALUE':
                switch (action.name) {
                    case 'firstName':
                        return {
                            ...state, tempUser: { ...state.tempUser, firstName: action.value }, authError: ''
                        };
                    case 'lastName':
                        return {
                            ...state, tempUser: { ...state.tempUser, lastName: action.value }, authError: ''
                        };
                    case 'email':
                        return {
                            ...state, tempUser: { ...state.tempUser, email: action.value }, authError: ''
                        };
                    case 'password':
                        return {
                            ...state, tempUser: { ...state.tempUser, password: action.value }, authError: ''
                        };
                }
                return state;
            case 'ERROR':
                return {
                    ...state, authError: action.error, needRegister: action.needRegister
                };
            default:
                return state;
        }
    }
};
