import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import { saveUser, loadUser, getDeviceState, getUploadFileData, UploadFile, AuthSend, getDownloadFile, finishUpdate, cleanup, requestPowerOff, requestPowerOn, clearDTCs, restartUpdate, mobileAppLogger, validateAuthCode, loginComplete } from '../devices/DeviceFactory';
import { User } from './Authentication';

export function javascriptSetupComplete(currentpage: string) {
    window.javascriptSetupFlag = true;
    loadUser();
    if (currentpage == 'update')
        loginComplete();
}

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface UpdateState {
    Sku: string;
    SerialNumber: string;
    DeviceState: any;
    IgnitionVersion: string;
    SessionExecutionCount: number;
    OS: string;
    UserGuid: any;
    Platform: string;
    ClientVersion: string;
    AllowStoreAtEnd: boolean;
    GotToCleanup: boolean;
    Token: string;

    installation: any;
    currentItemIndex: number;
	processingItem: boolean;
	autoProcessNextItem: boolean;
    notes: string;
    menuItems: any;
    currentFile: number;
	totalFiles: number;

	progress: number;
	progressMessage: string;

    isCheckingForUpdates: boolean;
    isUpToDate: boolean;
    needRegister: boolean;
    needLogout: boolean;
    retrieveOnly: boolean;
    userCancelled: boolean;
    ShowMopar: boolean;
    SaveDisabled: boolean;
    AuthCode1: string;
    AuthCode2: string;
    VerifiedAuth: string;
    AuthError: string;
    updateError: string;

    showMessage: string;
    mopar: string;

    finalDisplay: boolean;
    finalMessage: string;

    userFeedback: Array<string>;
}

export interface DeviceState {
    Sku: string;
    SerialNumber: string;
    AvailableSpace: string;
    OEM: string;
    MainVersions: string;
    AuxVersions: string;
    DSPICVersions: string;
    'info.xml': string;
    UpdaterVersion: string;
    HardwareVersion: string;
    BootloaderVersion: string;
    '/pulsar.XMC': string;
    '/pulsar4.FPGA': string;
    '/pulsar16.FPGA': string;
    'FPGAType': string;
    'FPGAVersion': string[];
    '/pulsar.CAL': string;
    '/Main/pulsar.XMC': string;
    '/Main/pulsar16.FPGA': string;
    '/Main/pulsar.CAL': string;
    '/Aux/pulsar.XMC': string;
    '/Aux/pulsar.CAL': string;
    '/module.PIC1': string;
    '/module.PIC2': string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface CheckGetDeviceState { type: 'CHECK_GET_DEVICE_STATE', deviceState: DeviceState }
export interface IsCheckingForUpdatesAction { type: 'IS_CHECKING_FOR_UDPATES', isCheckingForUpdates: boolean }
export interface TokenAction { type: 'TOKEN', token: string }
export interface RegisterAction { type: 'REGISTER', responseJson: string }
export interface DoLogoutAction { type: 'DO_LOGOUT' }
export interface InstallationAction { type: 'INSTALLATION', installation: any }
export interface OnClickPromptAction { type: 'ON_CLICK_PROMPT', selection: any }
export interface OnAuthWindow { type: 'AUTH_WINDOW', show: Boolean }
export interface OnAuthError { type: 'AUTH_ERROR', error: any }
export interface OnChangeText { type: 'ON_CHANGETEXT', event: any, target: any }
export interface ProcessItemAction { type: 'PROCESS_ITEM', proceed: boolean, autoProcessNextItem: boolean }
export interface ProcessingItemAction { type: 'PROCESSING_ITEM', processingItem: boolean }
export interface AutoProcessNextItemAction { type: 'AUTO_PROCESS_NEXT_ITEM', process: boolean }
export interface ProgressAction { type: 'PROGRESS', progress: number }
export interface ProgressMessageAction { type: 'PROGRESS_MESSAGE', progressMessage: string }
export interface UpdateFinishedAction { type: 'UPDATE_FINISHED', message: string }
export interface ShowMessageAction { type: 'SHOW_MESSAGE', message: string}
export interface ErrorAction { type: 'ERROR', error: string }
export interface UserFeedbackAction { type: 'USER_FEEDBACK', message: string }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = CheckGetDeviceState | IsCheckingForUpdatesAction | TokenAction | RegisterAction | DoLogoutAction | InstallationAction | OnClickPromptAction | ProcessItemAction | ProgressAction | ProcessingItemAction | AutoProcessNextItemAction | ProgressMessageAction | UpdateFinishedAction | ShowMessageAction | ErrorAction | OnChangeText | OnAuthError | OnAuthWindow | UserFeedbackAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export function handleErrors(response: any) {
    if (!response.ok) {
        return response.json();
    }

    return response;
}

function sendReceipt(dispatch: Function, getState: Function) {
    const appState = getState();

    let requestState = {
        Token: appState.update.Token,
        InstallationId: appState.update.installation.InstallationId,
        Sku: appState.update.Sku,
        SerialNumber: appState.update.SerialNumber,
        Exception: appState.update.updateError
    };

    mobileAppLogger("********** sending receipt");

    fetch(`Update/SendReceiptToServer`, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Token': appState.update.Token
        },
        body: JSON.stringify(requestState)
    })
        .then(response => handleErrors(response))
        .then(response => {
            if (response.error !== undefined)
                throw Error(response.error);

            return response;
        })
        .then(response => response.json() as Promise<any>)
        .then(message => {
            let json = JSON.parse(message);
            if (json.Result)
                message = json.Result;

            dispatch({ type: 'UPDATE_FINISHED', message });
        })
        .catch(error => {
            dispatch({ type: 'ERROR', error: error.message });
        })
}

function getAutoProcessNextItem(dispatch: Function, getState: Function) {
    const appState = getState();
    let autoProcessNextItem = false;

    if (appState && appState.update) {
        let state = appState.update;
        let nextItemIndex = state.currentItemIndex + 1;

        if (nextItemIndex < state.installation.Items.length) {
            let nextItem = state.installation.Items[nextItemIndex];
            if (nextItem != null && nextItem.Metadata != null) {
                if (nextItem.Metadata.PromptType == null)
                    autoProcessNextItem = true;
            }
        }
        else {
            if (!appState.update.isCheckingForUpdates) {
                finishUpdate();
                sendReceipt(dispatch, getState);
            }
        }
    }

    return autoProcessNextItem;
}

export const actionCreators = {
    doLogout: (): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'DO_LOGOUT' });
    },
    checkGetDeviceState: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        
		if (appState && appState.update) {
            let state = appState.update;
            window.OnDeviceState = function (deviceState: any) {

                // check for mopar anywhere in the device state value
                if (deviceState.Sku !== null && deviceState.Sku === "FCA9000")
                    state.ShowMopar = true;

				dispatch({ type: 'CHECK_GET_DEVICE_STATE', deviceState: deviceState });
			}

            getDeviceState();
        }
    },
    checkForUpdates: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.update && appState.update.Platform) {
            if (!appState.update.isCheckingForUpdates) {
                dispatch({ type: 'IS_CHECKING_FOR_UDPATES', isCheckingForUpdates: true });

                window.OnDeviceState = function (deviceState: any) {

                    let requestState = {
                        ...appState.update,
                        DeviceState: deviceState,
                    };

                    fetch('Handshaking/NewToken', {
                        method: 'post',
                        headers: { 'Content-Type': 'application/json', 'Token': 'UNSET' },
                        body: JSON.stringify(appState.update)
                    })
                        .then(response => response.json() as Promise<any>)
                        .then(tokenString => {
                            dispatch({ type: 'TOKEN', token: tokenString });

                            return fetch(`Update/CheckForUpdates`, {
                                method: 'post',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Token': tokenString
                                },
                                body: JSON.stringify(requestState)
                            });
                        })
                        .then(response => handleErrors(response))
                        .then(response => {
                            if (response.error !== undefined)
                                throw Error(response.error + 'parse checkupdate');

                            return response;
                        })
                        .then(response => response.json() as Promise<any>)
                        .then(installationString => {
                            let installation = JSON.parse(installationString);
                            try {
                                let metadata = JSON.parse(installation.Metadata);
                                installation.Metadata = metadata;
                                dispatch({ type: 'INSTALLATION', installation: installation });
                            }
                            catch {
                                dispatch({ type: 'REGISTER', responseJson: installationString });
							}
                        })
                        .catch(error => {
                            dispatch({ type: 'ERROR', error: error.message });
                        })
                };

                getDeviceState();
            }
        }
    },
    clearInstallation: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.update) {
            dispatch({ type: 'INSTALLATION', installation: null });
        }
    },
    onChangeText: (evt: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if(appState)
        {
            dispatch({type: 'ON_CHANGETEXT', event: evt.target.value, target: evt.target.name});
        }
    },
    onClickPrompt: (selection: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.update) {
			let state = appState.update;

            if(state.ShowMopar && selection !== 'Cancel'){
                
                let AuthString: AuthSend = {
                    SerialNumber: state.SerialNumber,
                    AuthKey: state.AuthCode1 + state.AuthCode2
                };
                    
                validateAuthCode(AuthString, (progress: number) => {

                    dispatch({ type: 'PROGRESS', progress: progress });
                }, (Message: string) => {
                    dispatch({ type: 'SHOW_MESSAGE', message: Message });
                }, (results: any) => {
                    
                    if(results == "success")
                    {
                        dispatch({type: 'AUTH_WINDOW', show: false});                           
                    }
                    else 
                    {
                        dispatch({ type: 'AUTH_ERROR', error: results });

                    }
                }, (error: any) => {
                    dispatch({ type: 'AUTH_ERROR', error: "Error with Authcode validation. \n{ " + error + " }"});
                });
            }

            dispatch({ type: 'ON_CLICK_PROMPT', selection: selection });

            if (state.installation && state.installation.Items && state.currentItemIndex >= state.installation.Items.length) {
                dispatch({ type: 'UPDATE_FINISHED', message: 'Update Finished' })
            }
        }
	},
	setProcessingItem: (processing: boolean): AppThunkAction<KnownAction> => (dispatch) => {
		dispatch({ type: 'PROCESSING_ITEM', processingItem: processing });
	},
	setAutoProcessNextItem: (process: boolean): AppThunkAction<KnownAction> => (dispatch) => {
		dispatch({ type: 'AUTO_PROCESS_NEXT_ITEM', process: process });
	},
    processItem: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
		if (appState && appState.update) {
			let state = appState.update;
			let proceed = false;

            if (state.installation != null && state.installation.Items != null && state.currentItemIndex >= 0) {
                if (state.currentItemIndex < state.installation.Items.length) {
                    let item = state.installation.Items[state.currentItemIndex];

                    if (item != null && item.Metadata != null) {
                        if (item.Metadata.Retrieve) {
                            var retrieveId = item.Metadata.Retrieve;

                            // HACK: look for "Eeprom" as a special case Retrieve type to avoid ( until below )
                            if (retrieveId != "Eeprom") {
                                let file: UploadFile = {
                                    Token: state.Token,
                                    Identifier: item.Identifier,
                                    NetworkUrl: item.Identifier,
                                    FileType: retrieveId,
                                    UserGuid: state.UserGuid,
                                    SerialNumber: state.SerialNumber,
                                    Item: item,
                                    FileData: null,
                                    Message: ''
                                };

                                try {
                                    dispatch({ type: 'PROGRESS', progress: 0 });

                                    getUploadFileData(file, (progress: number) => {
                                        dispatch({ type: 'PROGRESS', progress: progress });
                                    }, (successMessage: string) => {
                                        proceed = true;
                                        dispatch({ type: 'PROCESS_ITEM', proceed, autoProcessNextItem: getAutoProcessNextItem(dispatch, getState) });
                                    }, () => {
                                        proceed = true;
                                        dispatch({ type: 'PROCESS_ITEM', proceed, autoProcessNextItem: getAutoProcessNextItem(dispatch, getState) });
                                    }, (error: any) => {
                                        if (error.message)
                                            dispatch({ type: 'ERROR', error: error.message });
                                        else
                                            dispatch({ type: 'ERROR', error: error });
                                    });
                                }
                                catch (e) {
                                    dispatch({ type: 'ERROR', error: e.message });
                                }
                            }
                            //else
                            //	await Device.ExtendedStep(item, userGuid);
                        }
                        else if (item.Metadata.IgnitionMethod) {
                            /*
                            switch (metadata["IgnitionMethod"].Value<string>())
                            {
                                case "Extended":
                                    await Device.ExtendedStep(item, userGuid);
                                    break;
                            }
                            */
                        }
                        else if (item.Metadata.UpdateType) {
                            switch (item.Metadata.UpdateType) {
                                case 'F5Firmware':
                                case 'Firmware':
                                    //await Device.InstallFirmware(item.Identifier, item);
                                    break;

                                case 'File':
                                case 'Database':
                                case 'Calibration':
                                case 'Configuration':
                                    getDownloadFile(state.Token, item, (progress: number) => {

                                        dispatch({ type: 'PROGRESS', progress: progress });
                                    }, (progressMessage: string) => {
                                        dispatch({ type: 'PROGRESS_MESSAGE', progressMessage: progressMessage });
                                    }, (successMessage: string) => {
                                        proceed = true;
                                        dispatch({ type: 'PROCESS_ITEM', proceed, autoProcessNextItem: getAutoProcessNextItem(dispatch, getState) });
                                    }, (error: any) => {
                                        if (error.message)
                                            dispatch({ type: 'ERROR', error: error.message });
                                        else
                                            dispatch({ type: 'ERROR', error: error });
                                    });
                                    break;

                                case 'Prompt':
                                    dispatch({ type: 'PROCESS_ITEM', proceed, autoProcessNextItem: false });
                                    break;

                                default:
                                    //await Device.ExtendedStep(item, userGuid);
                                    break;
                            }
                        }
                        else {
                            dispatch({ type: 'ERROR', error: 'Did not understand update item ' + item.Metadata });
                        }
                    }
                }
                else {

                    // last step complete, finish update
                    finishUpdate();
                    cleanup();

                    // send the receipt to the server
                    sendReceipt(dispatch, getState);
                }
			}
        }
	},
	progressUpdate: (progress: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
		const appState = getState();
		if (appState && appState.update) {
			dispatch({ type: 'PROGRESS', progress: progress });
		}
    },
    requestDevicePowerOff: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        window.onPowerOffComplete = () => {
            window.onPowerOnComplete = () => {
                dispatch({ type: 'PROCESS_ITEM', proceed: true, autoProcessNextItem: getAutoProcessNextItem(dispatch, getState) });
            };

            requestPowerOn();

            dispatch({ type: 'SHOW_MESSAGE', message: 'Please turn the key back on, but do not start the vehicle.' });
        };

        requestPowerOff();
    },
    clearDTCs: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        window.onClearDTCsComplete = () => {
            dispatch({ type: 'PROCESS_ITEM', proceed: true, autoProcessNextItem: getAutoProcessNextItem(dispatch, getState) });
        };

        clearDTCs();
    },
    restartUpdate: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        window.onClearDTCsComplete = () => {
            dispatch({ type: 'PROCESS_ITEM', proceed: true, autoProcessNextItem: getAutoProcessNextItem(dispatch, getState) });
        };

        restartUpdate();
    },
    mobileAppLogger: (message: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        window.mobileAppLogger(message);
    },
    sendReceipt: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.update) {
            if (!appState.update.isCheckingForUpdates) {
                let requestState = {
                    Token: appState.update.Token,
                    InstallationId: appState.update.installation.id,
                    Sku: appState.update.Sku,
                    SerialNumber: appState.update.SerialNumber,
                    Exception: appState.update.updateError
                };

                fetch(`Update/SendReceiptToServer`, {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        'Token': appState.update.Token
                    },
                    body: JSON.stringify(requestState)
                })
                    .then(response => handleErrors(response))
                    .then(response => {
                        if (response.error !== undefined)
                            throw Error(response.error);

                        return response;
                    })
                    .then(response => response.json() as Promise<any>)
                    .then(message => {
                        dispatch({ type: 'UPDATE_FINISHED', message });
                    })
                    .catch(error => {
                        dispatch({ type: 'ERROR', error: error.message });
                    })
            }
        }
    },
    error: (error: string, needRegister: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.update) {
            dispatch({ type: 'ERROR', error: error });
        }
    },
    doUserFeedback: (message: string): AppThunkAction<KnownAction> => (dispatch) => {
        dispatch({ type: 'USER_FEEDBACK', message: message });
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: UpdateState = {
    Sku: '22400',
    SerialNumber: '299999999',
    DeviceState: null,

    IgnitionVersion: '1.0.0',
    SessionExecutionCount: 0,
    OS: 'Android',
    UserGuid: null,
    Platform: 'PULSAR',
    ClientVersion: '1.0.0',
    AllowStoreAtEnd: true,
    GotToCleanup: true,
    Token: '',

    installation: null,
	currentItemIndex: 0,
	processingItem: false,
	autoProcessNextItem: false,
    notes: '',
    menuItems: null,
    currentFile: 0,
	totalFiles: 0,

	progress: 0,
	progressMessage: '',

    isCheckingForUpdates: false,
    isUpToDate: false,
    needRegister: false,
    needLogout: false,
    retrieveOnly: false,
    userCancelled: false,
    updateError: '',
    AuthCode1: '',
    AuthCode2: '',
    VerifiedAuth: '',
    AuthError: '',
    ShowMopar: false,
    SaveDisabled: false,

    showMessage: '',
    mopar: '',

    finalDisplay: false,
    finalMessage: '',

    userFeedback: new Array<string>()
};

export const reducer: Reducer<UpdateState> = (state: UpdateState | undefined, incomingAction: Action): UpdateState => {

    if (state === undefined) {
        mobileAppLogger("state undefined update reducer");
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    let autoProcessNextItem = false;

    // I am doing this because I need to do things in our Update store when things happen in the Authentication store
    // I don't know of a way to access the Authenication store from this update reducer. I also noticed that all
    // reducers called called when any page needs the state updated. So I am looking at the Authentication messages
    // here. I can't just add them to the switch statement below because those are defined values specific to the
    // UpdateStore.
    // TODO: find a better or "proper" way to do that if possible
    let actionTypeString = action.type as string;
    let genericAction = action as any;
    if (actionTypeString === '@@router/LOCATION_CHANGE') {
        if (window.javascriptSetupFlag) {
            if (genericAction.payload != null && genericAction.payload.location != null && genericAction.payload.location.pathname != null && genericAction.payload.location.pathname == '/update') {
                loginComplete();
			}
        }
        return {
            ...state, needLogout: false
        };
    }
    else if (actionTypeString === 'LOGIN' || actionTypeString === 'REGISTER') {
        return {
            ...state, UserGuid: unloadedState.UserGuid, updateError: ''
        };
	}
    else if (actionTypeString === 'LOGOUT') {
        return {
            ...state, UserGuid: null, updateError: ''
        };
    }
    else if (actionTypeString === 'LOAD_USER') {
        let user = { email: '', password: '', firstName: '', lastName: '', guid: '', userID: 0 } as User;
        if (genericAction != null && genericAction.user != null) {
            let savedUser = genericAction.user as User;
            if (savedUser.guid != null && savedUser.guid.length > 0) {
                user = savedUser;
			}
		}
        return {
            ...state, UserGuid: user.guid, updateError: ''
        };
    }
    else {
        switch (action.type) {
            case 'CHECK_GET_DEVICE_STATE':
                state.Sku = action.deviceState.Sku;
                state.SerialNumber = action.deviceState.SerialNumber;

                let mainVersions: Map<string, string> = new Map<string, string>();
                mainVersions.set("Main", action.deviceState["/pulsar.XMC"].valueOf());
                try { mainVersions.set("Logic", action.deviceState["/pulsar4.FPGA"].valueOf()); } catch { }
                try { mainVersions.set("Logic", action.deviceState["/pulsar16.FPGA"].valueOf()); } catch { }
                mainVersions.set("Calibration", action.deviceState["/pulsar.CAL"].valueOf());

                let newDeviceState: DeviceState = action.deviceState;

                newDeviceState.MainVersions = '';
                mainVersions.forEach((value, key, map) => {
                    newDeviceState.MainVersions += key + ': ' + (value === null ? '--' : value) + ' ';
                });

                let auxVersions: Map<string, string> = new Map<string, string>();
                try { auxVersions.set("Auxiliary", action.deviceState["/Aux/pulsar.XMC"].valueOf()); } catch { }
                try { auxVersions.set("Calibration", action.deviceState["/Aux/pulsar.CAL"].valueOf()); } catch { }

                newDeviceState.AuxVersions = '';
                auxVersions.forEach((value, key, map) => {
                    newDeviceState.AuxVersions += key + ': ' + (value === null ? '--' : value) + ' ';
                });

                let dspicVersions: Map<string, string> = new Map<string, string>();
                try { dspicVersions.set("DSPIC1", action.deviceState["/module.PIC1"].valueOf()); } catch { }
                try { dspicVersions.set("DSPIC2", action.deviceState["/module.PIC2"].valueOf()); } catch { }

                newDeviceState.DSPICVersions = '';
                dspicVersions.forEach((value, key, map) => {
                    newDeviceState.DSPICVersions += key + ': ' + (value === null ? '--' : value) + ' ';
                });

                return {
                    ...state, DeviceState: newDeviceState
                };
            case 'IS_CHECKING_FOR_UDPATES':
                return {
                    ...state, currentItemIndex: 0, userCancelled: false, isCheckingForUpdates: action.isCheckingForUpdates, updateError: '', showMessage: ''
                };
            case 'TOKEN':
                return {
                    ...state,
                    Token: action.token
                }
            case 'REGISTER':
                let needRegistration = false;
                if (action.responseJson != null && action.responseJson.length > 0) {
                    needRegistration = true;
                    saveUser('');
                }

                return {
                    ...state,
                    needRegister: needRegistration, updateError: ''
                }
            case 'DO_LOGOUT':
                saveUser('');
                return {
                    ...state,
                    needLogout: true
                }
            case 'INSTALLATION':
                let notes: string = state.notes;
                let menuItems: any = null;
                let sessionExecutionCount = state.SessionExecutionCount + 1;
                let currentFile = 0;
                let totalFiles = 0;
                let retrieveFiles = 0;
                let isUpToDate = false;
                let needOptIn = true;
                let finalMessage = state.finalMessage;
                autoProcessNextItem = false;
                let retrieveOnly = false;
                let addFileCountToNotes = false;
                let needRegister = false;

                if (action.installation != null) {
                    if (action.installation.Metadata != null) {
                        if (action.installation.Metadata.UpToDate != null)
                            isUpToDate = action.installation.Metadata.UpToDate;

                        menuItems = action.installation.Metadata.Notes;

                        if (action.installation.Metadata.Register != null && (action.installation.Metadata.Register as boolean)) {
                            needRegister = true;
                        }
                    }

                    if (action.installation.Package != null) {
                        notes = action.installation.Package;

                        let index: number = notes.indexOf(' r');
                        if (index >= 0)
                            notes = notes.substring(0, index) + ' Revision ' + notes.substring(index + 2, notes.length);

                        if (notes.indexOf('Pulsar Pulsar') >= 0)
                            notes = notes.substring(7, notes.length);

                        notes = 'Package ' + notes;

                        addFileCountToNotes = true;
                    }

                    if (action.installation.Items != null) {
                        let insertOptInIndex = -1;
                        let itemIndex = 0;

                        action.installation.Items = Object.keys(action.installation.Items).map((key, index) => {
                            let item = action.installation.Items[index];

                            let metadata = JSON.parse(item.Metadata);
                            if (metadata != null) {
                                if (sessionExecutionCount > 1 || !metadata.SessionFlag || metadata.SessionFlag != 'DoOneTime') {
                                    if (metadata.UpdateType != null || metadata.Noop == null || metadata.Noop != true || metadata.Retrieve != null) {
                                        if (metadata.PromptType != null) {
                                            // we have to massage some of the values based on the prompt type
                                            switch (metadata.PromptType) {
                                                case 'YesContinueNoExit':
                                                    metadata.ShowNo = true;
                                                    break;
                                                case 'Waiting':
                                                    metadata.IsWaiting = true;
                                                    break;
                                            }
                                        }

                                        if (metadata.UpdateType != null) {
                                            if ((metadata.UpdateType as string).toLowerCase().indexOf('file') == 0)
                                                totalFiles++;

                                            if (metadata.IsCritical != null && metadata.IsCritical) {
                                                needOptIn = false;
                                            }

                                            if (needOptIn && insertOptInIndex == -1 && state.DeviceState.Sku != '22400')
                                                insertOptInIndex = itemIndex;
                                            else if (state.DeviceState.Sku == '22400' && metadata.PromptType != null && (metadata.PromptType as string).toLowerCase().indexOf("okexit") != 0)
                                                insertOptInIndex = itemIndex;

                                            itemIndex++;
                                        }

                                        if (metadata.Retrieve != null) {
                                            autoProcessNextItem = true;
                                            retrieveFiles++;
                                        }

                                        item.Metadata = metadata;

                                        return item;
                                    }
                                }
                            }

                            return null;

                        }).filter((element) => {
                            return element != null;
                        });

                        if (needOptIn && insertOptInIndex > -1) {
                            (action.installation.Items as Array<any>).splice(insertOptInIndex, 0,
                                {

                                    Identifier: "",
                                    Metadata:
                                    {
                                        UpdateType: "Prompt",
                                        Message: "Updates are available for your device. Do you want to install them now?",
                                        PromptType: "YesContinueNoExit",
                                        ShowNo: true,
                                        Title: "Update Now?",
                                        SessionFlag: "DoOneTime"
                                    }
                                });
                            autoProcessNextItem = false;
                        }
                    }

                    if (notes !== null && addFileCountToNotes)
                        notes += ' (' + totalFiles.toString() + ' file' + (totalFiles > 1 ? 's)' : ')');
                }
                else
                    finalMessage = '';

                if (totalFiles == 0 && retrieveFiles == 0) {
                    return {
                        ...state,
                        SessionExecutionCount: sessionExecutionCount,
                        installation: { ...action.installation },
                        isUpToDate: true,
                        needRegister: needRegister,
                        notes: notes,
                        menuItems: menuItems,
                        currentFile: currentFile,
                        totalFiles: totalFiles,
                        isCheckingForUpdates: false,
                        updateError: '',
                        finalMessage: finalMessage,
                        showMessage: ''
                    };
                }

                retrieveOnly = (totalFiles == 0 && retrieveFiles > 0);

                return {
                    ...state,
                    SessionExecutionCount: sessionExecutionCount,
                    installation: { ...action.installation },
                    isUpToDate: isUpToDate,
                    needRegister: needRegister,
                    retrieveOnly: retrieveOnly,
                    notes: notes,
                    menuItems: menuItems,
                    currentFile: currentFile,
                    totalFiles: totalFiles,
                    isCheckingForUpdates: false,
                    updateError: '',
                    finalMessage: finalMessage,
                    showMessage: '',
                    autoProcessNextItem: autoProcessNextItem
                };
            case 'ON_CHANGETEXT':
                if (action.target == 'one') {
                    if (action.event.length > 4) {
                        return {
                            ...state, AuthCode1: state.AuthCode1
                        };
                    }
                    return {
                        ...state, AuthCode1: action.event
                    };
                }
                else {
                    if (action.event.length > 4) {
                        return {
                            ...state, AuthCode2: state.AuthCode2
                        };
                    }
                    return {
                        ...state, AuthCode2: action.event
                    };
                }
            case 'ON_CLICK_PROMPT':
                let userCancelled = false;
                let nextItemIndex = state.currentItemIndex + 1;
                autoProcessNextItem = false;

                if (action.selection == 'Save') {

                    if (state.ShowMopar)
                        return {
                            ...state, SaveDisabled: true, currentItemIndex: userCancelled ? state.currentItemIndex : nextItemIndex, isCheckingForUpdates: false, userCancelled: userCancelled, autoProcessNextItem: autoProcessNextItem, showMessage: 'Validating AuthCode...'
                        };

                }

                if (state.installation != null && state.installation.Items != null && state.currentItemIndex >= 0 && state.currentItemIndex < state.installation.Items.length) {
                    let item = state.installation.Items[state.currentItemIndex];
                    if (item != null && item.Metadata != null) {
                        let promptType = item.Metadata.PromptType;
                        if (promptType != null) {
                            if ((promptType == "YesContinueNoExit" && action.selection !== "OK")
                                || (promptType == "OkExit" && action.selection === "OK")
                                || (promptType == "Waiting" && action.selection !== "OK")) {

                                userCancelled = true;
                            }

                        }
                    }

                    if (nextItemIndex < state.installation.Items.length) {
                        let nextItem = state.installation.Items[nextItemIndex];
                        if (nextItem != null && nextItem.Metadata != null) {
                            if (nextItem.Metadata.PromptType == null)
                                autoProcessNextItem = true;
                        }
                    }
                    else {
                        autoProcessNextItem = true;
                    }
                }

                return {
                    ...state, currentItemIndex: userCancelled ? state.currentItemIndex : nextItemIndex, isCheckingForUpdates: false, userCancelled: userCancelled, autoProcessNextItem: autoProcessNextItem, showMessage: ''
                };
            case 'AUTH_WINDOW':
                return {

                    ...state, AuthError: '', ShowMopar: false, isCheckingForUpdates: false, autoProcessNextItem: true, SaveDisabled: false, VerifiedAuth: state.AuthCode1 + state.AuthCode2
                };
            case 'AUTH_ERROR':
                return {
                    ...state, ShowMopar: true, AuthError: action.error, isCheckingForUpdates: false, showMessage: '', SaveDisabled: false
                };
            case 'PROCESS_ITEM':
                return {
                    ...state, processingItem: false, currentItemIndex: action.proceed ? state.currentItemIndex + 1 : state.currentItemIndex, isCheckingForUpdates: false, autoProcessNextItem: action.autoProcessNextItem, showMessage: ''
                };
            case 'PROCESSING_ITEM':
                return {
                    ...state, processingItem: action.processingItem, showMessage: ''
                };
            case 'AUTO_PROCESS_NEXT_ITEM':
                return {
                    ...state, autoProcessNextItem: action.process, showMessage: ''
                };
            case 'PROGRESS':
                return {
                    ...state, progress: action.progress, showMessage: ''
                };
            case 'PROGRESS_MESSAGE':
                return {
                    ...state, progressMessage: action.progressMessage, showMessage: ''
                };
            case 'UPDATE_FINISHED':
                finishUpdate();
                return {
                    ...state, installation: null, currentItemIndex: 0, processingItem: false, autoProcessNextItem: false, menuItems: null, currentFile: 0, totalFiles: 0, progress: 0, progressMessage: '', finalMessage: '', finalDisplay: true, showMessage: ''
                };
            case 'SHOW_MESSAGE':
                return {
                    ...state, AuthError: '', showMessage: action.message
                };
            case 'ERROR':
                return {
                    ...state, updateError: action.error, isCheckingForUpdates: false, showMessage: ''
                };
            case 'USER_FEEDBACK':
                let userFeedback = new Array<string>();
                let index = 0;
                let addValue = true;
                state.userFeedback.forEach((value) => {
                    let line = value;
                    if (index++ == state.userFeedback.length - 1) {
                        if (value.startsWith(action.message)) {
                            line += '.';
                            addValue = false;
                        }
					}
                    userFeedback.push(line)
                });
                if (addValue)
                    userFeedback.push(action.message);
                return {
                    ...state, userFeedback: userFeedback
                };
            default:
                return state;
        }
    }
};
