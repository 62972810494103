import { DeviceState } from '../store/Update';
import { UploadFile } from '../devices/DeviceFactory';

export const Mock = {
	saveUser: function (user: string) {
		if (user == null || user.length <= 0) {
			localStorage.removeItem('user');
		}
		else {
			localStorage.setItem('user', user);
		}
	},

	loadUser: function () {
		if (window.onLoadUser != null) {
			let user = localStorage.getItem('user');
			window.onLoadUser(user);
		}
	},

	loginComplete: function () {
		console.log('Mock device login complete');
	},

	getDeviceState: function () {
		if (window.OnDeviceState) {
		    /* L5P
			window.OnDeviceState({
				Sku: '22400',
				SerialNumber: '299999999',
				'info.xml': 'stuff',
				UpdaterVersion: "2",
				HardwareVersion: '0',
				BootloaderVersion: '7',
				'/pulsar.XMC': '1,',
				'/pulsar16.FPGA': '1',
				'FPGAType': '16',
				'FPGAVersion': ['1', '0'],
				'/pulsar.CAL': '1',
				'/Main/pulsar.XMC': '1',
				'/Main/pulsar16.FPGA': '1',
				'/Main/pulsar.CAL': '1',
				'/Aux/pulsar.XMC': '1',
				'/Aux/pulsar.CAL': '1'
			} as DeviceState);
			//*/
			//* L5P - Up to date
			window.OnDeviceState({
				Sku: '22400',
				SerialNumber: '299999999',
				'info.xml': 'stuff',
				UpdaterVersion: "2",
				HardwareVersion: '0',
				BootloaderVersion: '7',
				'/pulsar.XMC': '45,',
				'/pulsar16.FPGA': '73',
				'FPGAType': '16',
				'FPGAVersion': ['73', '0'],
				'/pulsar.CAL': '19',
				'/Main/pulsar.XMC': '45',
				'/Main/pulsar16.FPGA': '73',
				'/Main/pulsar.CAL': '19',
				'/Aux/pulsar.XMC': '27',
				'/Aux/pulsar.CAL': '12'
			} as DeviceState);
			//*/
			/* Hemi
			window.OnDeviceState({
				Sku: '32454',
				SerialNumber: '201643091',
				'info.xml': 'stuff',
				UpdaterVersion: "2",
				HardwareVersion: '0',
				BootloaderVersion: '7',
				'/pulsar.XMC': '1,',
				'/pulsar4.FPGA': '1',
				'FPGAType': '4',
				'FPGAVersion': ['1', '0'],
				'/pulsar.CAL': '1'
			});
			//*/
			/* Hemi - Up to date
			window.OnDeviceState({
				Sku: '32454',
				SerialNumber: '201643091',
				'info.xml': 'stuff',
				UpdaterVersion: "2",
				HardwareVersion: '0',
				BootloaderVersion: '7',
				'/pulsar.XMC': '54,',
				'/pulsar4.FPGA': '29',
				'FPGAType': '4',
				'FPGAVersion': ['29', '0'],
				'/pulsar.CAL': '9'
			});
			//*/
		}
	},

	getUploadFileData(uploadFile: UploadFile) {
		let uploadData: any = null;

		if (uploadFile.FileType.indexOf('ErrorLog.txt') >= 0) {
			uploadData = new Buffer("Priority     Type         Code     IntData          FloatData" +
				"0           0            0         0           0" +
				"0           0            0         0           0" +
				"0           0            0         0           0" +
				"0           0            0         0           0" +
				"0           0            0         0           0" +
				"0           0            0         0           0" +
				"0           0            0         0           0" +
				"0           0            0         0           0").toString('base64');
		}

		uploadFile.FileData = uploadData;

		let progress = 0;
		let progressFunction = () => {
			window.onUploadFileProgress(progress);
			progress += 10;

			if (progress < 50)
				setTimeout(progressFunction, 50);
			else
				window.sendUploadFile(uploadFile);
		};

		setTimeout(progressFunction, 0);
	},

	processDownloadFile(downloadFile: any) {
		let progress = 0;
		window.onDownloadFileProgressMessage('');

		let progressFunction = () => {
			window.onDownloadFileProgress(progress);
			progress += 10;

			if (progress < 50)
				setTimeout(progressFunction, 50);
			else
				window.onDownloadFileFinished("Download complete");
		};

		setTimeout(progressFunction, 0);
	},

	finishUpdate() {
		console.log('Mock device finish update');
	},

	cleanup() {
		console.log('Mock device cleanup');
	},

	requestPowerOff() {
		console.log('Mock device request power off');

		if (window.onPowerOffComplete)
			setTimeout(window.onPowerOffComplete, 1000);
	},

	requestPowerOn() {
		console.log('Mock device request power on');

		if (window.onPowerOnComplete)
			setTimeout(window.onPowerOnComplete, 1000);
	},

	clearDTCs() {
		console.log('Mock device clear DTCs');

		if (window.onClearDTCsComplete)
			setTimeout(window.onClearDTCsComplete, 1000);
	},
	restartUpdate() {
		console.log('Mock device restart update.');
	},
	mobileAppLogger(message: string) {
		console.log('Mock device logger:' + message);
	}
}
