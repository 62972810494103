import { Mock } from './Mock'
import { invokeCSCode } from '../helpers/jsinteraction';
import { handleErrors } from '../store/Update';

export interface UploadFile {
	Token: string,
	Identifier: string,
	NetworkUrl: string,
	FileType: string,
	UserGuid: string,
	SerialNumber: string,
	FileData: any,
	Item: any,
	Message: string
}
export interface AuthSend {
	SerialNumber: string,
	AuthKey: string	
}

export function showAlert() {
	invokeCSCode('This is an alert');
}

export function saveUser(user: string) {
	console.log("saveUser");
	if (window.saveUser != null) {
		console.log("In saveUser null check.");
		window.saveUser(user);
		return;
	}

	console.log("window.saveUser null. Using mock device.");

	Mock.saveUser(user);
}

export function loadUser() {
	console.log("loadUser");
	if (window.loadUser != null) {
		console.log("In loadUser null check.");
		window.loadUser();
		return;
	}

	console.log("window.loadUser null. Using mock device.");

	Mock.loadUser();
}

export function loginComplete() {
	console.log("loginComplete");
	if (window.loginComplete != null) {
		console.log("In loginComplete null check.");
		window.loginComplete();
		return;
	}

	console.log("window.loginComplete null. Using mock device.");

	Mock.loginComplete();
}

export function getDeviceState() {
	// choose between mock device and other devices
	console.log("getDeviceState");
	if (window.getDeviceState != null) {
		console.log("In getDeviceState null check.");
		window.getDeviceState();
		return;
	}

	console.log("window.getDeviceState null. Using mock device.");

	Mock.getDeviceState();
}

export function validateAuthCode(authCode: AuthSend, onProgress: Function, onProgressMessage: Function, onFinished: Function, onError: Function){
	window.onDownloadFileProgress = onProgress;
	window.onDownloadFileProgressMessage = onProgressMessage;
	window.onAuthCodeFinished = onFinished;
	window.onAuthCodeError = onError;
	
	fetch(`Update/AuthCode`, {
		method: 'post',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(authCode)
	})
		.then(response => handleErrors(response))
		.then(response => {
			if (response.error !== undefined){
				window.onAuthCodeError(response.error);
				throw Error(response.error);
			}
			return response;
		})
		.then(response => response.json() as Promise<any>)
		.then(successMessage => {
			let message = JSON.parse(successMessage);

			window.onAuthCodeFinished(message.status);
			
		})
		.catch(error => {
			window.onAuthCodeError(error);
			
		})
}

export function getUploadFileData(uploadFile: UploadFile, onProgress: Function, onFinished: Function, notFound: Function, onError: Function) {

	window.onUploadFileProgress = onProgress;
	window.onUploadFileFinished = onFinished;
	window.noFileFound = notFound;
	window.onUploadFileError = onError;

	window.sendUploadFile = (uploadFile: any) => {
		sendUploadFile(uploadFile);
	};

	if (window.getUploadFileData != null)
		return window.getUploadFileData(uploadFile);

	console.log("window.getUploadFileData null. Using mock device.");

	return Mock.getUploadFileData(uploadFile);
}

export function noFileFound() {
	return window.noFileFound();
}

export function sendUploadFile(uploadFile: UploadFile) {

	if (uploadFile.FileData != null && (uploadFile.FileData as string).length > 0) {
		fetch(`Update/UploadFile`, {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
				'Token': uploadFile.Token
			},
			body: JSON.stringify(uploadFile)
		})
			.then(response => handleErrors(response))
			.then(response => {
				if (response.error !== undefined)
					throw Error(response.error);

				return response;
			})
			.then(response => response.json() as Promise<any>)
			.then(successMessage => {
				window.onUploadFileFinished(successMessage);
			})
			.catch(error => {
				window.onUploadFileError(error);
			})
	}
	else {
		window.onUploadFileFinished('Success');
	}
}

export function getDownloadFile(token: string, item: any, onProgress: Function, onProgressMessage: Function, onFinished: Function, onError: Function) {

	window.onDownloadFileProgress = onProgress;
	window.onDownloadFileProgressMessage = onProgressMessage;
	window.onDownloadFileFinished = onFinished;
	window.onDownloadFileError = onError;
	
	
	if (item.Metadata.hasOwnProperty('TempFile')) {
		
		let downloadFile = {
			Identifier: token,
			Filename: "",
			Filedata: "",
			Item: item
		}
		processDownloadFile(downloadFile);
	}
	else {
		let downloadFile = {
			Token: token,
			item: JSON.stringify(item)
		};

		fetch(`Update/DownloadFile`, {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
				'Token': token
			},
			body: JSON.stringify(downloadFile)
		})
			.then(response => handleErrors(response))
			.then(response => {
				if (response.error !== undefined)
					throw Error(response.error);

				return response;
			})
			.then(response => response.json() as Promise<any>)
			.then(downloadFile => {

				downloadFile = JSON.parse(downloadFile);

				if (downloadFile.Item != null) {
					let item = JSON.parse(downloadFile.Item);
					if (item != null) {
						if (item.Metadata != null) {

							item.Metadata.MainCal = (item.Metadata.MainCal == 'true');
							item.Metadata.SetBootloaderMode = (item.Metadata.SetBootloaderMode == 'true');

							downloadFile.Item = item;

							processDownloadFile(downloadFile);
						}
					}
				}
			})
			.catch(error => {
				window.onDownloadFileError(error);
			})
	}

}

export function processDownloadFile(downloadFile: any) {

	if (window.processDownloadFile != null)
		return window.processDownloadFile(downloadFile);

	console.log("window.processDownloadFile null. Using mock device.");

	return Mock.processDownloadFile(downloadFile);
}

export function finishUpdate() {

	if (window.finishUpdate != null) {
		console.log("In finishUpdate null check.");
		return window.finishUpdate();
	}

	console.log("window.finishUpdate null. Using mock device.");

	return Mock.finishUpdate();
}

export function cleanup() {

	if (window.cleanup != null)
		return window.cleanup();

	console.log("window.cleanup null. Using mock device.");

	return Mock.cleanup();
}

export function requestPowerOff() {

	if (window.requestPowerOff != null)
		return window.requestPowerOff();

	console.log("window.requestPowerOff null. Using mock device.");

	return Mock.requestPowerOff();
}

export function requestPowerOn() {

	if (window.requestPowerOn != null)
		return window.requestPowerOn();

	console.log("window.requestPowerOn null. Using mock device.");

	return Mock.requestPowerOn();
}

export function clearDTCs() {

	if (window.clearDTCs != null)
		return window.clearDTCs();

	console.log("window.clearDTCs null. Using mock device.");

	return Mock.clearDTCs();
}

export function restartUpdate() {
	if (window.restartUpdate != null)
		return window.restartUpdate();

	console.log("window.restartUpdate null. Using mock device.");

	return Mock.restartUpdate();
}

export function mobileAppLogger(message: string) {

	console.log(message);

	if (window.mobileAppLogger != null)
		return window.mobileAppLogger(message);

	console.log("window.mobileAppLogger null. Using mock device.");

	return Mock.mobileAppLogger(message);
}
